import {Decimal} from "Figured/Assets/Modules/decimal.js";
import lang from "Figured/Assets/Modules/lang";
import _ from "lodash";
import stringMath from "string-math";

/**
 * TODO: we should refactor all Math calls into this module.
 *       this includes things like abs, min, max etc.
 */

/**
 *
 * @param number
 *
 * @returns {number}
 */
export function abs(number) {
    return number >= 0 ? number : -number;
}

/**
 * Cleans the string of illegal chars before converting.
 *
 * @param value
 *
 * @returns {Number}
 */
export function parse_number(value) {
    if (!value) {
        value = 0;

    } else if (_.isString(value)) {
        value = Number(value.replace(/[^\d.-]/g, ""));

    } else if (value instanceof Decimal) {
        value = value.toNumber();

    } else {
        value = Number(value);
    }

    return isFinite(value) ? value : 0;
}

/**
 * A reverse of `currency_format`, used to reverse report display values to
 * float values that can be used in calculations.
 */
export function parse_currency(amount) {
    let number = String(amount).trim().replace(/[$£€R,\s]/g, "");

    // Invalid / Empty value (reports)
    if (number === "-") {
        return 0;
    }

    // Negative values
    if (number[0] === "(") {
        return _.toNumber(number.substring(1, number.length - 1)) * -1;
    }

    return _.toNumber(number);
}

/**
 * @param {Decimal|Number|string} amount
 * @param {int}                   decimals
 * @param {Boolean}               commas
 *
 * @returns {String}
 */
export function number_format(amount, decimals = 0, commas = true) {
    let number = parse_number(amount);

    return number.toLocaleString("en", {
        useGrouping: commas,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

/**
 * @param {Decimal|Number|string} amount
 * @param {Boolean}               cents
 *
 * @returns {String}
 */
export function accounting_format(amount, cents = true) {
    let number = parse_number(amount);

    /* Check zero, which might be shown as "-" on some reports. */
    if (number === 0) {
        return "-";
    }

    return number.toLocaleString("en", {
        useGrouping: true,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

/**
 * Formats a monetary value as currency, eg.
 *     - currency_format(5000)            => $50,000
 *     - currency_format(5000, true)      => $50,000.00
 *     - currency_format(5000, true, 'R') => R50,000.00
 *
 * @param {number|string|Decimal}  amount The monetary amount to format
 * @param {boolean} cents  Whether or not to show cents.
 * @param {string}  symbol The currency symbol to use, or false if n/a.
 * @param {boolean} showZero
 *
 * @returns {String}
 */
export function currency_format(amount, cents = false, symbol = lang.trans("units.$"), showZero = true) {
    let number = parse_number(amount);

    /* Check zero, which might be shown as "-" on some reports. */
    if (number === 0 && !showZero) {
        return "-";
    }

    let isNegative = number < 0;
    let formatted  = number_format(abs(number), cents ? 2 : 0, true);

    if (isNegative) {
        return `(${symbol}${formatted})`;
    } else {
        return `${symbol}${formatted}`;
    }
}

/**
 * Safely handles a zero divisor by returning 0.
 *
 * @param {Decimal|string|number} value
 * @param {Decimal|string|number} divisor
 *
 * @returns {Number}
 */
export function safe_divide(value, divisor) {
    let op1 = new Decimal(value   || 0);
    let op2 = new Decimal(divisor || 0);

    let res = op2.isZero() ? 0 : op1.dividedBy(op2).toNumber();

    return isFinite(res) ? res : 0;
}

/**
 * Parses input values to Decimal and returns their sum
 *
 * @param  {...[number|string|Decimal]} values
 * @return {Number}
 */
export function decimal_sum (...values)
{
    return _.toNumber(_.reduce(_.flatten(values), (accumulator, value) => accumulator.add(value), new Decimal(0)))
}

/**
 * Normalizes and rounds the provided value.
 * Will accept values such as "1.5+2*3" and return 7.5 (dependent on the provided decimalPrecision)
 * Will accept values with commas as thousands separators such as "1,500.5*2" by stripping commas and return 3001 (dependent on the provided decimalPrecision)
 *
 * @param {String} value The value to round
 * @param {Number} decimalPrecision The precision to use
 *
 * @returns {Number}
 */
export function normalizeStringMathValue(value, decimalPrecision = 2)
{
    // Apply stringMath to deal with any basic math equations
    let mathValue = stringMath(value.replace(/,/g, ""),
        (error, result) => {
            console.log("StringMath callback: error --> ", error, " result --> ", result)
        });

    // Make sure we handle the default StringMath value of null if not a valid math string e.g. a space
    mathValue = mathValue || 0;

    const decimalValue = new Decimal(mathValue);

    return decimalValue.toDecimalPlaces(decimalPrecision, Decimal.ROUND_HALF_UP).toNumber();
}

